@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://use.typekit.net/orz2eqx.css');

body {
  background-color: #FFF;
  font-family: 'dico-sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.tasman {
  font-family: tasman, sans-serif;
}

.dico-sans {
  font-family: dico-sans, sans-serif;
}

.dico-code {
  font-family: dico-code-two, mono;
}

/* .ais-SearchBox-reset {
  display: none;
} */

.ais-SearchBox-submit {
  background-color: #FF6B57;
}

/* Smaller font size for screens 768px or narrower */
@media (max-width: 768px) {
  .ais-SearchBox-input::placeholder {
    font-size: 13px;
  }
}


/* Custom CSS to change the layout to grid and make it full width */
.ais-Hits {
  display: grid;
  grid-template-columns: 1fr; /* Set the number of columns as needed */
  width: 100%;
}

.ais-Hits-item {
  width: 100%; /* Make each item take the full width of the container */
}